import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpBackend) // Load translations from external files
  .use(LanguageDetector) // Detect language from browser
  .use(initReactI18next) // Bind with React
  .init({
    fallbackLng: "nl", // Fallback language
    debug: false,
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Translation file path
    },
  });

export default i18n;
