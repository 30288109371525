import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { UserProvider } from "./context/UserContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { FuneralHomeProvider } from "./context/FuneralHomeContext";
import "./i18n";

import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AuthWrapper: React.FC = () => {
  const { user } = useAuth0();  

  return user ? (
    <FuneralHomeProvider>
      <App />
    </FuneralHomeProvider>
  ) : (
    <App />
  );
};

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID || ""}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        connection: "google-oauth2", // Forces Google login
      }}
    >
      <Router>
        <UserProvider>
          <AuthWrapper />
        </UserProvider>
      </Router>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
