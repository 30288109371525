import { useTranslation } from "react-i18next";
import { useFuneralHome } from "../context/FuneralHomeContext";
import FuneralHomeDropdown from "./dropdown/FuneralHomeDropdown";

function FuneralHomeHandler() {
  const {
    funeralHomes,
    isLoading,
    error,
    selectedFuneralHome,
    setSelectedFuneralHome,
  } = useFuneralHome();
  const { t } = useTranslation();

  const handleFuneralHomeChange = (selectedId: string) => {
    const temp = funeralHomes.find((home) => home.FUNERALHOMEID === selectedId);
    if (temp) {
      setSelectedFuneralHome(temp);
    }
  };

  return (
    <>
      {isLoading && <p className="text-info">{t("loading.funeralhome")}</p>}
      {error && (
        <p className="text-danger">
          {t("error.funeralhome", { message: error })}
        </p>
      )}

      {/* Select Funeral Home Dropdown */}
      {funeralHomes.length > 0 && (
        <div className="form-group mt-3">
          <label htmlFor="funeralHomeSelect">{t("funeralhome.select")}</label>
          <FuneralHomeDropdown
            selectedFuneralHome={selectedFuneralHome?.FUNERALHOMEID || ""}
            handleChange={(value) => handleFuneralHomeChange(value)}
          />
        </div>
      )}

      {!selectedFuneralHome && funeralHomes.length > 0 && (
        <p className="text-info mt-3">{t("funeralhome.selectrequired")}</p>
      )}
    </>
  );
}
export default FuneralHomeHandler;
