import { useCallback, useEffect, useState } from "react";
import { useFuneralHome } from "../../context/FuneralHomeContext";
import { AccountingApi } from "../../api/accountingApi";
import { StockData, StockTransferData } from "../../models/StockData";
import { FuneralHome } from "../../models/FuneralHome";
import FuneralHomeHandler from "../FuneralHomeHandler";
import { useTranslation } from "react-i18next";
import FuneralHomeDropdown from "../dropdown/FuneralHomeDropdown";

function StockTransfer() {
  const { funeralHomes, selectedFuneralHome } = useFuneralHome();
  const { t } = useTranslation();

  const [stockTransferOverview, setStockTransferOverview] = useState<
    StockTransferData[]
  >([]);
  const [selectedToFuneralHome, setSelectedToFuneralHome] =
    useState<FuneralHome | null>(null);
  const [isLoadingStock, setIsLoadingStock] = useState<boolean>(false);
  const [errorStock, setErrorStock] = useState<string | null>(null);

  const [errorTransfer, setErrorTransfer] = useState<string | null>(null);
  const [successTransfer, setSuccessTransfer] = useState<boolean>(false);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const fetchStockOverview = useCallback(() => {
    if (!selectedFuneralHome) return;

    setIsLoadingStock(true);
    setSelectedToFuneralHome(null);
    setStockTransferOverview([]);
    setErrorStock(null);
    AccountingApi.fetchStockOverview(
      selectedFuneralHome.COMPANY_ID,
      selectedFuneralHome.COSTCODE
    )
      .then((data) => {
        const stockTransferData: StockTransferData[] = data.map(
          (item: StockData) => ({
            ...item,
            transferQuantity: 0, // Set correctionQuantity as inventory
          })
        );
        setStockTransferOverview(stockTransferData);
      })
      .catch((error) => {
        let errorMessage = error.message;
        if (error.response?.data.ERROR) {
          errorMessage = error.response.data.ERROR;
        }
        setErrorStock(errorMessage);
      })
      .finally(() => {
        setIsLoadingStock(false);
      });
  }, [selectedFuneralHome]);

  const handleTransfer = () => {
    if (!selectedFuneralHome || !selectedToFuneralHome) {
      return;
    }

    setIsProcessing(true);
    setErrorTransfer(null);
    AccountingApi.createStockTransfer(
      selectedFuneralHome.COMPANY_ID,
      selectedFuneralHome.COSTCODE,
      selectedToFuneralHome.COSTCODE,
      stockTransferOverview
    )
      .then((data) => {
        setSuccessTransfer(true);
      })
      .catch((error) => {
        let errorMessage = error.message;
        if (error.response?.data.ERROR) {
          errorMessage = error.response.data.ERROR;
        }
        setErrorTransfer(errorMessage);
      })
      .finally(() => {
        fetchStockOverview();
        setIsProcessing(false);
      });
  };

  const handleToFuneralHomeChange = (selectedId: string) => {
    const selectedHome = funeralHomes.find(
      (home) => home.FUNERALHOMEID === selectedId
    );
    setSuccessTransfer(false);
    setSelectedToFuneralHome(selectedHome || null);
  };

  const isFormValid = () => {
    return (
      isFuneralHomeValid() &&
      stockTransferOverview.some(
        (item) =>
          item.transferQuantity > 0 && item.transferQuantity <= item.inventory
      )
    );
  };

  const isFuneralHomeValid = () => {
    return (
      selectedToFuneralHome &&
      selectedToFuneralHome.FUNERALHOMEID !==
        selectedFuneralHome?.FUNERALHOMEID &&
      selectedToFuneralHome.COMPANY_ID === selectedFuneralHome?.COMPANY_ID &&
      selectedToFuneralHome.COSTCODE !== selectedFuneralHome?.COSTCODE
    );
  };

  const handleQuantityChange = (id: string, value: number) => {
    const updatedData = stockTransferOverview.map((item) =>
      item.id === id ? { ...item, transferQuantity: value } : item
    );
    setStockTransferOverview(updatedData);
  };

  // Effect to run when the component mounts or when selectedFuneralHome changes
  useEffect(() => {
    fetchStockOverview(); // Fetch data
  }, [fetchStockOverview, selectedFuneralHome]); // Dependency array

  return (
    <div className="container mt-5">
      <h1>{t("transfer.title")}</h1>
      <p>{t("transfer.information")}</p>

      <FuneralHomeHandler />

      {successTransfer && (
        <p className="text-success mt-4">{t("transfer.success")}</p>
      )}
      {/* Display Stock Overview Data */}
      {selectedFuneralHome != null && (
        <div className="mt-4">
          {!isLoadingStock && stockTransferOverview.length === 0 && (
            <p className="text-info">{t("stockoverview.no_stock")}</p>
          )}
          {/* Loading and Error States */}
          {isLoadingStock && <p className="text-info">{t("loading.stock")}</p>}
          {errorStock && (
            <p className="text-danger">
              {t("error.stock", { message: errorStock })}
            </p>
          )}
          {isProcessing && <p className="text-info">{t("loading.request")}</p>}

          {stockTransferOverview.length > 0 && (
            <>
              <table className="mt-4 table table-striped">
                <thead>
                  <tr>
                    <th>{t("stockoverview.table.sereninumber")}</th>
                    <th>{t("stockoverview.table.suppliernumber")}</th>
                    <th>{t("stockoverview.table.description")}</th>
                    <th>{t("stockoverview.table.category")}</th>
                    <th>{t("stockoverview.table.amount")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {stockTransferOverview.map((item) => (
                    <tr key={item.id}>
                      <td>{item.no}</td>
                      <td>{item.no2}</td>
                      <td>{item.description2}</td>
                      <td>{t("productcategory." + item.itemCategoryCode)}</td>
                      <td>{item.inventory}</td>
                      <td>
                        <input
                          type="number"
                          className="form-control me-2"
                          placeholder={t("purchaseorder.table.quantity")}
                          min={0}
                          max={item.inventory}
                          value={item.transferQuantity}
                          onChange={(e) =>
                            handleQuantityChange(
                              item.id,
                              +e.target.value
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Select the funeral home to transfer to */}
              <div className="form-group mt-4">
                <label htmlFor="funeralHomeSelect">
                  {t("transfer.receivingfuneralhome")}
                </label>
                <FuneralHomeDropdown
                  selectedFuneralHome={
                    selectedToFuneralHome?.FUNERALHOMEID || ""
                  }
                  handleChange={(value) => handleToFuneralHomeChange(value)}
                />
                {!isFuneralHomeValid() && (
                  <p className="text-danger">
                    {t("transfer.mismatchedfuneralhome")}
                  </p>
                )}
              </div>

              {errorTransfer && (
                <p className="text-danger">
                  {t("error.transfer", { message: errorTransfer })}
                </p>
              )}

              <div>
                {/* Start transfer button */}
                {stockTransferOverview.length > 0 && (
                  <button
                    className="btn btn-primary mt-4 mr-2"
                    onClick={handleTransfer}
                    disabled={isProcessing || !isFormValid()}
                  >
                    {t("transfer.submit")}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default StockTransfer;
