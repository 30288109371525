import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Define types for the context
interface UserContextType {
  user: any;
  roles: string[];
  funeralHomes: string[];
  claims: any;
  loading: boolean;
  error: string | null;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const [roles, setRoles] = useState<string[]>([]);
  const [funeralHomes, setFuneralHomes] = useState<string[]>([]);
  const [claims, setClaims] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isAuthenticated && user) {
      try {
        // Extract custom claims and roles
        const sereniClaims =
          user[process.env.REACT_APP_AUTH_BASE_URL + "sereniClaims"];
        const sereniRoles =
          user[process.env.REACT_APP_AUTH_BASE_URL + "sereniRoles"];

        // If claims or roles are missing, log out the user
        if (!sereniClaims || !sereniRoles) {
          logout({
            logoutParams: { returnTo: window.location.origin },
          });
          return;
        }

        // Parse claims
        setClaims(sereniClaims);

        // Parse funeral homes if present
        const homes = sereniClaims.funeralHome
          ? sereniClaims.funeralHome.split(",")
          : [];
        setFuneralHomes(homes);

        // Set roles
        setRoles(sereniRoles);
        setLoading(false);
      } catch (err) {
        setError("Error parsing user data");
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, user, logout]);

  return (
    <UserContext.Provider
      value={{ user, roles, funeralHomes, claims, loading, error }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
