import React from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "./context/UserContext";
import { Routes, Route, useNavigate } from "react-router-dom";
import StockCorrection from "./components/StockCorrection/StockCorrection";
import PurchaseOrder from "./components/PurchaseOrder/PurchaseOrder";
import LanguageSwitcher from "./components/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import StockTransfer from "./components/StockTransfer/StockTransfer";

function App() {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { loading, error } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (loading) {
    return <div>{t("user.loading")}</div>;
  }

  if (error) {
    return (
      <div>
        {t("user.error")}: {error}
      </div>
    );
  }

  function navbarListItems() {
    if (!isAuthenticated) return <> </>;

    return (
      <>
        <li className="nav-item">
          <button
            className="nav-link btn btn-link"
            onClick={() => navigate("/stock-correction")}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            {t("navbar.stockcorrection")}
          </button>
        </li>
        <li className="nav-item">
          <button
            className="nav-link btn btn-link"
            onClick={() => navigate("/stock-transfer")}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            {t("navbar.stocktransfer")}
          </button>
        </li>
        <li className="nav-item">
          <button
            className="nav-link btn btn-link"
            onClick={() => navigate("/purchase-order")}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            {t("navbar.purchaseorders")}
          </button>
        </li>
      </>
    );
  }

  function logoutButton() {
    if (!isAuthenticated) return <> </>;

    return (
      <li className="nav-item">
        <button
          className="btn btn-outline-danger"
          onClick={() =>
            logout({
              logoutParams: { returnTo: window.location.origin },
            })
          }
        >
          {t("user.logout")}
        </button>
      </li>
    );
  }

  function mainContent() {
    if (!isAuthenticated) {
      return (
        <div className="d-flex vh-100 justify-content-center align-items-center bg-light">
          <div
            className="card p-4 shadow-lg"
            style={{ maxWidth: "400px", width: "100%" }}
          >
            <div className="card-body text-center">
              <h1 className="h3 mb-3 font-weight-normal">{t("login.title")}</h1>
              <p>{t("login.information")}</p>
              <button
                className="btn btn-primary btn-block"
                onClick={() => loginWithRedirect()}
              >
                {t("login.submit")}
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        {/* Routes */}
        <Routes>
          <Route
            path="/"
            element={
              <div className="container mt-5">
                <h1>{t("welcome")}</h1>
                <button
                  className="btn btn-primary mt-3 mx-2"
                  onClick={() => navigate("/stock-correction")}
                >
                  {t("navbar.stockcorrection")}
                </button>
                <button
                  className="btn btn-primary mt-3 mx-2"
                  onClick={() => navigate("/stock-transfer")}
                >
                  {t("navbar.stocktransfer")}
                </button>
                <button
                  className="btn btn-primary mt-3 mx-2"
                  onClick={() => navigate("/purchase-order")}
                >
                  {t("navbar.purchaseorders")}
                </button>
              </div>
            }
          />
          <Route path="/stock-correction" element={<StockCorrection />} />
          <Route path="/stock-transfer" element={<StockTransfer />} />
          <Route path="/purchase-order" element={<PurchaseOrder />} />
        </Routes>
      </>
    );
  }

  return (
    <div>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <button
            className="navbar-brand btn btn-link"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            {t("navbar.stockmanagement")}
          </button>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto">{navbarListItems()}</ul>
            <ul className="navbar-nav ms-auto">
              <LanguageSwitcher />
              {logoutButton()}
            </ul>
          </div>
        </div>
      </nav>
      {mainContent()}
    </div>
  );
}

export default App;
