import { accountingApi } from "../config/axiosConfigs";
import { CorrectionRequest } from "../models/requests/CorrectionRequest";
import { PurchaseOrderLine } from "../models/PurchaseOrderLine";
import { TransferRequest } from "../models/requests/TransferRequest";
import { PurchaseOrderRequest } from "../models/requests/PurchaseOrderRequest";
import { StockCorrectionData, StockTransferData } from "../models/StockData";

export const AccountingApi = {
  fetchStockOverview: async function (companyId: string, costCode: string) {
    console.debug("fetching stockoverview");

    const response = await accountingApi.request({
      url: "/get/" + companyId + "/" + costCode,
      method: "GET",
      params: {},
      data: {},
    });
    return response.data;
  },

  createStockTransfer: async function (
    companyId: string,
    currentLocation: string,
    newLocation: string,
    items: StockTransferData[]
  ) {
    console.debug("creating stock transfer");

    // DocumentNo needs to be a string generated from current date: YYYYMMDDHHMMSS + the costcode
    // Example: 20211005123456QUIBRA
    let documentNo =
      new Date()
        .toISOString()
        .replace(/[^0-9]/g, "")
        .substring(0, 14) + currentLocation;

    let request: TransferRequest = {
      COMPANY_ID: companyId,
      DOCUMENT_NO: documentNo,
      LOCATION_CODE: currentLocation,
      NEW_LOCATION_CODE: newLocation,
      ITEMS: [],
    };

    // Loop over items and add them to the request if amount is not 0
    items.forEach((item) => {
      if (item.transferQuantity <= 0) {
        return;
      }
      request.ITEMS.push({
        NO: item.no,
        QUANTITY: item.transferQuantity,
      });
    });
    if (request.ITEMS.length === 0) {
      throw new Error("No items to transfer");
    }

    const response = await accountingApi.request({
      url: "/transfer",
      method: "POST",
      params: {},
      data: request,
    });
    return response.data;
  },

  createStockCorrection: async function (
    companyId: string,
    currentLocation: string,
    items: StockCorrectionData[]
  ) {
    console.debug("creating stock correction");

    // DocumentNo needs to be a string generated from current date: YYYYMMDDHHMMSS + the costcode
    // Example: 20211005123456QUIBRA
    let documentNo =
      new Date()
        .toISOString()
        .replace(/[^0-9]/g, "")
        .substring(0, 14) + currentLocation;

    let request: CorrectionRequest = {
      COMPANY_ID: companyId,
      DOCUMENT_NO: documentNo,
      LOCATION_CODE: currentLocation,
      ITEMS: [],
    };

    // Loop over items and add them to the request if amount is not 0
    items.forEach((item) => {
      var quantity = item.correctionQuantity - item.inventory;
      if (quantity === 0) {
        return;
      }
      request.ITEMS.push({
        NO: item.no,
        QUANTITY: quantity,
      });
    });
    if (request.ITEMS.length === 0) {
      throw new Error("No items to correct");
    }

    const response = await accountingApi.request({
      url: "/correction",
      method: "POST",
      params: {},
      data: request,
    });
    return response.data;
  },

  createPurchaseOrder: async function (
    companyId: string,
    vendorNumber: string,
    vendorOrderNumber: string,
    costCode: string,
    purchaseOrderLines: PurchaseOrderLine[]
  ) {
    console.debug("creating purchase order");

    let request: PurchaseOrderRequest = {
      COMPANY_ID: companyId,
      VENDOR_NUMBER: vendorNumber,
      VENDOR_ORDER_NO: vendorOrderNumber,
      LOCATION_CODE: costCode,
      PURCHASE_ORDER_LINES: [],
    };

    // Loop over items and add them to the request if amount is not 0
    purchaseOrderLines.forEach((line) => {
      if (line.QUANTITY === 0 || line.NO === "") {
        return;
      }
      request.PURCHASE_ORDER_LINES.push(line);
    });
    if (request.PURCHASE_ORDER_LINES.length === 0) {
      throw new Error("No items to purchase");
    }

    const response = await accountingApi.request({
      url: "/purchaseorder",
      method: "POST",
      params: {},
      data: request,
    });
    return response.data;
  },
};
