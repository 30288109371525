import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { SereniApi } from "../api/sereniApi";
import { FuneralHome } from "../models/FuneralHome";
import { Product } from "../models/Product";
import { Supplier } from "../models/Supplier";

interface FuneralHomeContextProps {
  funeralHomes: FuneralHome[];
  products: Product[];
  suppliers: Supplier[];
  isLoading: boolean;
  error: string | null;
  selectedFuneralHome: FuneralHome | null;
  setSelectedFuneralHome: (funeralHome: FuneralHome) => void;
  setProducts: (products: Product[]) => void;
  setSuppliers: (suppliers: Supplier[]) => void;
}

const FuneralHomeContext = createContext<FuneralHomeContextProps | undefined>(
  undefined
);

export const FuneralHomeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth0();
  const [funeralHomes, setFuneralHomes] = useState<FuneralHome[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedFuneralHome, setSelectedFuneralHome] =
    useState<FuneralHome | null>(null);

  const [products, setProducts] = useState<Product[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const selectFuneralHome = (funeralHome: FuneralHome) => {
    setSelectedFuneralHome(funeralHome);
    setProducts([]);
  };

  useEffect(() => {
    const fetchFuneralHomes = async () => {
      try {
        const data = await SereniApi.fetchAllFuneralhomes();
        setFuneralHomes(data);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchFuneralHomes();
    }

    return () => {
      setFuneralHomes([]);
    };

  }, [user]);

  return (
    <FuneralHomeContext.Provider
      value={{
        funeralHomes,
        products,
        suppliers,
        isLoading,
        error,
        selectedFuneralHome,
        setSelectedFuneralHome: selectFuneralHome,
        setProducts,
        setSuppliers,
      }}
    >
      {children}
    </FuneralHomeContext.Provider>
  );
};

export const useFuneralHome = () => {
  const context = useContext(FuneralHomeContext);
  if (!context) {
    throw new Error("useFuneralHome must be used within a FuneralHomeProvider");
  }
  return context;
};
