import axios from "axios";

export const sereniApi = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_API_BEARER_TOKEN}`,
  },
});

export const accountingApi = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_ACCOUNTING_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_ACCOUNTING_BEARER_TOKEN}`,
  },
});
