import Select from "react-select";
import { useFuneralHome } from "../../context/FuneralHomeContext";
import { useTranslation } from "react-i18next";

interface FuneralHomeDropdownProps {
  selectedFuneralHome: string;
  handleChange: (selectedFuneralHome: string) => void;
}

const FuneralHomeDropdown: React.FC<FuneralHomeDropdownProps> = ({
  selectedFuneralHome,
  handleChange,
}) => {
  const { funeralHomes } = useFuneralHome();
  const { t } = useTranslation();

  const funeralHomeOptions = funeralHomes.map((funeralHome) => ({
    value: funeralHome.FUNERALHOMEID,
    label: funeralHome.NAME,
  }));

  funeralHomeOptions.unshift({ value: "", label: t("funeralhome.select") });

  const handleSelectChange = (selectedOption: any) => {
    handleChange(selectedOption?.value || "");
  };

  return (
    <Select
      value={funeralHomeOptions.find(
        (option) => option.value === selectedFuneralHome
      )}
      onChange={handleSelectChange}
      options={funeralHomeOptions}
      placeholder={t("funeralhome.select")}
    />
  );
};

export default FuneralHomeDropdown;
