import Select from "react-select";
import { useFuneralHome } from "../../context/FuneralHomeContext";
import { useTranslation } from "react-i18next";
import { Product } from "../../models/Product";

interface ProductDropdownProps {
  selectedProduct: string;
  handleProductChange: (selectedProduct: Product | undefined) => void;
}

const ProductDropdown: React.FC<ProductDropdownProps> = ({
  selectedProduct,
  handleProductChange,
}) => {
  const { i18n, t } = useTranslation();
  const { products } = useFuneralHome();

  const productOptions = products.map((product) => ({
    value: product.CODE,
    label: `(${product.CODE}) - ${
      i18n.language === "fr" ? product.FRENCH : product.DUTCH
    }`,
  }));

  const handleSelectChange = (selectedOption: any) => {
    var product = products.find(
      (product) => product.CODE === selectedOption?.value
    );
    handleProductChange(product);
  };

  productOptions.unshift({ value: "", label: t("product.select") });

  return (
    <Select
      value={productOptions.find((option) => option.value === selectedProduct)}
      onChange={handleSelectChange}
      options={productOptions}
      placeholder={t("product.select")}
      isClearable
    />
  );
};

export default ProductDropdown;
