import { useEffect, useState } from "react";
import { useFuneralHome } from "../../context/FuneralHomeContext";
import { SereniApi } from "../../api/sereniApi";
import { Product } from "../../models/Product";
import { Supplier } from "../../models/Supplier";
import { PurchaseOrderLine } from "../../models/PurchaseOrderLine";
import FuneralHomeHandler from "../FuneralHomeHandler";
import { AccountingApi } from "../../api/accountingApi";
import ProductDropdown from "../dropdown/ProductDropdown";
import SupplierDropdown from "../dropdown/SupplierDropdown";
import { useTranslation } from "react-i18next";

function PurchaseOrder() {
  const {
    products,
    suppliers,
    selectedFuneralHome,
    setProducts,
    setSuppliers,
  } = useFuneralHome();
  const { t } = useTranslation();

  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
  const [errorProducts, setErrorProducts] = useState<string | null>(null);

  const [isLoadingSuppliers, setIsLoadingSuppliers] = useState<boolean>(false);
  const [errorSuppliers, setErrorSuppliers] = useState<string | null>(null);

  const [vendorNumber, setVendorNumber] = useState<string>("");

  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(
    null
  );
  const [isProcessingOrder, setIsProcessingOrder] = useState<boolean>(false);
  const [errorOrder, setErrorOrder] = useState<string | null>(null);

  const [purchaseOrderLines, setPurchaseOrderLines] = useState<
    Array<PurchaseOrderLine>
  >([]);

  const handleSupplierChange = (selectedId: string) => {
    const selectedSupplier = suppliers.find(
      (home) => home.SUPPLIERID === selectedId
    );
    setSelectedSupplier(selectedSupplier || null);
  };

  const addDeliveryLine = () => {
    let newLine: PurchaseOrderLine = {
      NO: "",
      DESCRIPTION: "",
      DIRECT_UNIT_COST: 1,
      QUANTITY: 0,
    };
    setPurchaseOrderLines((prev) => [...prev, newLine]);
  };

  const handleLineChange = (index: number, field: string, value: any) => {
    const updatedLines = purchaseOrderLines.map((line, i) => {
      if (i === index) {
        return { ...line, [field]: value };
      }
      return line;
    });
    setPurchaseOrderLines(updatedLines);
  };

  const handleProductChange = (index: number, product: Product | undefined) => {
    const updatedLines = purchaseOrderLines.map((line, i) => {
      if (i === index) {
        return {
          ...line,
          NO: product?.CODE || "",
          DESCRIPTION: product?.DUTCH || "",
        };
      }
      return line;
    });
    setPurchaseOrderLines(updatedLines);
  };

  const deleteLine = (index: number) => {
    setPurchaseOrderLines(purchaseOrderLines.filter((_, i) => i !== index));
  };

  const isFormValid = () => {
    return (
      vendorNumber &&
      purchaseOrderLines.every(
        (line) =>
          line.NO !== "" &&
          line.QUANTITY > 0 &&
          line.DESCRIPTION &&
          line.DESCRIPTION.trim() !== ""
      )
    );
  };

  const handlePurchaseOrder = () => {
    if (!selectedFuneralHome || !selectedSupplier) {
      return;
    }

    setIsProcessingOrder(true);
    setErrorOrder(null);
    AccountingApi.createPurchaseOrder(
      selectedFuneralHome.COMPANY_ID,
      selectedSupplier.EXTERNAL_ID,
      vendorNumber,
      selectedFuneralHome.COSTCODE,
      purchaseOrderLines
    )
      .then((data) => {
        setSelectedSupplier(null);
        setPurchaseOrderLines([]);
        setVendorNumber("");
      })
      .catch((error) => {
        let errorMessage = error.message;
        if (error.response?.data.ERROR) {
          errorMessage = error.response.data.ERROR;
        }
        setErrorOrder(errorMessage);
      })
      .finally(() => {
        setIsProcessingOrder(false);
      });
  };

  const fetchProducts = () => {
    if (
      products.length > 0 ||
      selectedFuneralHome == null ||
      selectedSupplier == null
    )
      return;
    // Fetch products for selected funeral
    setIsLoadingProducts(true);
    SereniApi.fetchProducts(selectedSupplier.SUPPLIERID)
      .then((data: Product[]) => {
        setProducts(data);
      })
      .catch((error) => {
        let errorMessage = error.message;
        if (error.response?.data.ERROR) {
          errorMessage = error.response.data.ERROR;
        }
        setErrorProducts(errorMessage);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  const fetchSuppliers = () => {
    if (suppliers.length > 0) return;
    // Fetch products for selected funeral
    setIsLoadingSuppliers(true);
    SereniApi.fetchSuppliers()
      .then((data: Supplier[]) => {
        setSuppliers(data);
      })
      .catch((error) => {
        let errorMessage = error.message;
        if (error.response?.data.ERROR) {
          errorMessage = error.response.data.ERROR;
        }
        setErrorSuppliers(errorMessage);
      })
      .finally(() => {
        setIsLoadingSuppliers(false);
      });
  };

  useEffect(() => {
    setErrorProducts(null);
    setErrorSuppliers(null);
    fetchSuppliers();
    if (selectedSupplier) fetchProducts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFuneralHome, selectedSupplier]); // Dependency array

  return (
    <div className="container mt-5">
      <h1>{t("purchaseorder.title")}</h1>
      <p>{t("purchaseorder.information")}</p>

      <FuneralHomeHandler />

      {/* Display Stock Overview Data */}
      {selectedFuneralHome != null && (
        <div className="mt-4">
          {/* Loading and Error States */}
          {isLoadingProducts && (
            <p className="text-info">{t("loading.product")}</p>
          )}
          {errorProducts && (
            <p className="text-danger">
              {t("error.product", { message: errorProducts })}
            </p>
          )}
          {isLoadingSuppliers && (
            <p className="text-info">{t("loading.supplier")}</p>
          )}
          {errorSuppliers && (
            <p className="text-danger">
              {t("error.supplier", { message: errorSuppliers })}{" "}
            </p>
          )}
          {/* Select Supplier Dropdown */}
          {suppliers.length > 0 && (
            <div className="form-group mt-4">
              <label htmlFor="supplierSelect">{t("supplier.select")}</label>
              <SupplierDropdown
                selectedSupplier={selectedSupplier?.SUPPLIERID || ""}
                handleChange={(value) => handleSupplierChange(value)}
              />
            </div>
          )}

          {!selectedSupplier && suppliers.length > 0 && (
            <p className="text-info mt-4">{t("supplier.selectrequired")}</p>
          )}

          {/* Button to add a new line to the list of delivered products. */}
          {selectedSupplier != null && (
            <>
              <div className="form-group mt-4">
                <label htmlFor="vendorNumber">
                  {t("purchaseorder.vendornumber")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={vendorNumber}
                  onChange={(e) => setVendorNumber(e.target.value)}
                />
              </div>
              <button
                className="btn btn-primary mt-4"
                onClick={addDeliveryLine}
                disabled={isLoadingProducts || isProcessingOrder}
              >
                {t("purchaseorder.addnewline")}
              </button>
            </>
          )}
          <div>
            {purchaseOrderLines.length > 0 && (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{t("purchaseorder.table.product")}</th>
                    <th>{t("purchaseorder.table.quantity")}</th>
                    <th>{t("purchaseorder.table.cost")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseOrderLines.map((line, index) => (
                    <tr key={index}>
                      <td style={{ width: "40%" }}>
                        {/* Product Dropdown */}
                        <ProductDropdown
                          selectedProduct={line.NO}
                          handleProductChange={(value) =>
                            handleProductChange(index, value)
                          }
                        />
                      </td>
                      <td>
                        {/* Quantity Input */}
                        <input
                          type="number"
                          className="form-control me-2"
                          placeholder={t("purchaseorder.table.quantity")}
                          min={1}
                          value={line.QUANTITY}
                          onChange={(e) =>
                            handleLineChange(
                              index,
                              "QUANTITY",
                              +e.target.value
                            )
                          }
                        />
                      </td>
                      <td>
                        {/* Quantity Input */}
                        <input
                          type="number"
                          className="form-control me-2"
                          placeholder={t("purchaseorder.table.cost")}
                          min={1}
                          step={0.01}
                          value={line.DIRECT_UNIT_COST}
                          onChange={(e) =>
                            handleLineChange(
                              index,
                              "DIRECT_UNIT_COST",
                              parseFloat(e.target.value)
                            )
                          }
                        />
                      </td>
                      <td>
                        {/* Delete Line */}
                        <button
                          className="btn btn-danger"
                          onClick={() => deleteLine(index)}
                          disabled={isProcessingOrder}
                        >
                          {t("general.delete")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {/* Button to submit the list of products */}
            {selectedSupplier != null && purchaseOrderLines.length > 0 && (
              <button
                className="btn btn-primary mt-3"
                onClick={handlePurchaseOrder}
                disabled={!isFormValid() || isProcessingOrder}
              >
                {t("purchaseorder.submit")}
              </button>
            )}

            {/* Loading and Error States */}
            {isProcessingOrder && (
              <p className="mt-3 text-info">{t("loading.order")}</p>
            )}
            {errorOrder && (
              <p className="mt-3 text-danger">
                {t("error.order", { message: errorOrder })}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default PurchaseOrder;
