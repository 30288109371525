import { sereniApi } from "../config/axiosConfigs";
import { FloatingStock } from "../models/FloatingStock";
import { FuneralHome } from "../models/FuneralHome";
import { Product } from "../models/Product";
import { FloatingStockRequest } from "../models/requests/FloatingStockRequest";
import { StockData } from "../models/StockData";
import { Supplier } from "../models/Supplier";

export const SereniApi = {
  fetchAllFuneralhomes: async function (): Promise<FuneralHome[]> {
    console.debug("fetching funeral homes");

    const response = await sereniApi.request({
      url: "/funeralhomes",
      method: "GET",
      params: {},
      data: {},
    });

    return response.data;
  },

  fetchProducts: async function (supplierId: string): Promise<Product[]> {
    console.debug("fetching products");
    let url = "/products/" + supplierId;
    const response = await sereniApi.request({
      url: url,
      method: "GET",
      params: {},
      data: {},
    });

    return response.data;
  },

  fetchSuppliers: async function (): Promise<Supplier[]> {
    console.debug("fetching suppliers");

    const response = await sereniApi.request({
      url: "/suppliers",
      method: "GET",
      params: {},
      data: {},
    });

    return response.data;
  },

  fetchFloatingStock: async function (
    costCode: string,
    stocks: StockData[]
  ): Promise<FloatingStock[]> {
    console.debug("fetching floating stock");

    let request: FloatingStockRequest = {
      COSTCODE: costCode,
      PRODUCTCODES: [],
    };

    stocks.forEach((stock) => {
      request.PRODUCTCODES.push(stock.no);
    });

    const response = await sereniApi.request({
      url: "/floatingstock/find",
      method: "POST",
      params: {},
      data: request,
    });

    return response.data;
  },
};
