import { useCallback, useEffect, useState } from "react";
import { useFuneralHome } from "../../context/FuneralHomeContext";
import { AccountingApi } from "../../api/accountingApi";
import { StockCorrectionData, StockData } from "../../models/StockData";
import FuneralHomeHandler from "../FuneralHomeHandler";
import { useTranslation } from "react-i18next";
import { SereniApi } from "../../api/sereniApi";
import { FloatingStock } from "../../models/FloatingStock";

function StockCorrection() {
  const { selectedFuneralHome } = useFuneralHome();
  const { t } = useTranslation();

  const [stockCorrectionOverview, setStockCorrectionOverview] = useState<
    StockCorrectionData[]
  >([]);
  const [isLoadingStock, setIsLoadingStock] = useState<boolean>(false);
  const [errorStock, setErrorStock] = useState<string | null>(null);

  const [isLoadingFloatingStock, setIsLoadingFloatingStock] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorFloatingStock, setErrorFloatingStock] = useState<string | null>(
    null
  );
  const [floatingStock, setFloatingStock] = useState<FloatingStock[]>([]);

  const [errorCorrection, setErrorCorrection] = useState<string | null>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const fetchStockOverview = useCallback(() => {
    if (!selectedFuneralHome) return;

    setIsLoadingStock(true);
    setStockCorrectionOverview([]);
    setErrorStock(null);
    AccountingApi.fetchStockOverview(
      selectedFuneralHome.COMPANY_ID,
      selectedFuneralHome.COSTCODE
    )
      .then((data) => {
        const stockCorrectionData: StockCorrectionData[] = data.map(
          (item: StockData) => ({
            ...item,
            correctionQuantity: item.inventory, // Set correctionQuantity as inventory
          })
        );
        setStockCorrectionOverview(stockCorrectionData);
        fetchFloatingStock(stockCorrectionData);
      })
      .catch((error) => {
        let errorMessage = error.message;
        if (error.response?.data.ERROR) {
          errorMessage = error.response.data.ERROR;
        }
        setErrorStock(errorMessage);
      })
      .finally(() => {
        setIsLoadingStock(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFuneralHome]);

  const handleCorrection = () => {
    if (!selectedFuneralHome) {
      return;
    }

    setIsProcessing(true);
    setErrorCorrection(null);
    AccountingApi.createStockCorrection(
      selectedFuneralHome.COMPANY_ID,
      selectedFuneralHome.COSTCODE,
      stockCorrectionOverview
    )
      .then((data) => {})
      .catch((error) => {
        let errorMessage = error.message;
        if (error.response?.data.ERROR) {
          errorMessage = error.response.data.ERROR;
        }
        setErrorCorrection(errorMessage);
      })
      .finally(() => {
        fetchStockOverview();
        setIsProcessing(false);
      });
  };

  const fetchFloatingStock = (stockData: StockData[]) => {
    if (!selectedFuneralHome || stockData.length === 0) return;

    setIsLoadingFloatingStock(true);
    setErrorFloatingStock(null);
    SereniApi.fetchFloatingStock(selectedFuneralHome.COSTCODE, stockData)
      .then((data) => {
        setFloatingStock(data);
      })
      .catch((error) => {
        let errorMessage = error.message;
        if (error.response?.data.ERROR) {
          errorMessage = error.response.data.ERROR;
        }
        setErrorFloatingStock(errorMessage);
      })
      .finally(() => {
        setIsLoadingFloatingStock(false);
      });
  };

  const handleQuantityChange = (id: string, value: number) => {
    const updatedData = stockCorrectionOverview.map((item) =>
      item.id === id ? { ...item, correctionQuantity: value } : item
    );
    setStockCorrectionOverview(updatedData);
  };

  // Effect to run when the component mounts or when selectedFuneralHome changes
  useEffect(() => {
    fetchStockOverview(); // Fetch data
  }, [fetchStockOverview, selectedFuneralHome]); // Dependency array

  return (
    <div className="container mt-5">
      <h1>{t("correction.title")}</h1>
      <p>
        {t("correction.information")} <br />
        {t("correction.information_1")}
      </p>

      <FuneralHomeHandler />

      {/* Display Stock Overview Data */}
      {selectedFuneralHome != null && (
        <div className="mt-4">
          {!isLoadingStock && stockCorrectionOverview.length === 0 && (
            <p className="text-info">{t("correction.no_stock")}</p>
          )}
          {/* Loading and Error States */}
          {isLoadingStock && <p className="text-info">{t("loading.stock")}</p>}
          {stockCorrectionOverview.length > 0 && (
            <>
              <table className="mt-4 table table-striped">
                <thead>
                  <tr>
                    <th>{t("stockoverview.table.sereninumber")}</th>
                    <th>{t("stockoverview.table.suppliernumber")}</th>
                    <th>{t("stockoverview.table.description")}</th>
                    <th>{t("stockoverview.table.category")}</th>
                    <th>{t("stockoverview.table.amount")}</th>
                    <th>{t("stockoverview.table.amountnotinvoiced")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {stockCorrectionOverview.map((item) => (
                    <tr key={item.id}>
                      <td>{item.no}</td>
                      <td>{item.no2}</td>
                      <td>{item.description2}</td>
                      <td>{t("productcategory." + item.itemCategoryCode)}</td>
                      <td>{item.inventory}</td>
                      <td>
                        {isLoadingFloatingStock && (
                          <>
                            <span className="spinner-border spinner-border-sm"></span>
                          </>
                        )}
                        {!isLoadingFloatingStock && (
                          <>
                            {
                              floatingStock.find(
                                (floatingItem) => floatingItem.CODE === item.no
                              )?.TOTAL
                            }
                          </>
                        )}
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control me-2"
                          placeholder={t("purchaseorder.table.quantity")}
                          min={0}
                          value={item.correctionQuantity}
                          onChange={(e) =>
                            handleQuantityChange(item.id, +e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {errorStock && (
                  <p className="text-danger">
                    {t("error.stock", { message: errorStock })}
                  </p>
                )}
                {isProcessing && (
                  <p className="text-info">{t("loading.request")}</p>
                )}

                {errorCorrection && (
                  <p className="text-danger">
                    {t("error.correction", { message: errorCorrection })}
                  </p>
                )}
                {/* Start correction button */}
                {stockCorrectionOverview.length > 0 && (
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleCorrection}
                    disabled={isProcessing}
                  >
                    {t("correction.submit")}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
export default StockCorrection;
