import { useTranslation } from "react-i18next";

const LanguageSwitcher: React.FC = () => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng: string) => {
    if (i18n.language !== lng) i18n.changeLanguage(lng);
  };

  return (
    <div className="d-flex mx-4">
      <li className="nav-item">
        <button
          className="nav-link btn btn-link"
          onClick={
            i18n.language !== "nl" ? () => changeLanguage("nl") : undefined
          }
          style={
            i18n.language !== "nl"
              ? { cursor: "pointer", textDecoration: "none" }
              : {
                  cursor: "default",
                  textDecoration: "none",
                  fontWeight: "bold",
                }
          }
        >
          {t("navbar.language.dutch")}
        </button>
      </li>
      <li className="nav-item">
        <button
          className="nav-link btn btn-link"
          onClick={
            i18n.language !== "fr" ? () => changeLanguage("fr") : undefined
          }
          style={
            i18n.language !== "fr"
              ? { cursor: "pointer", textDecoration: "none" }
              : {
                  cursor: "default",
                  textDecoration: "none",
                  fontWeight: "bold",
                }
          }
        >
          {t("navbar.language.french")}
        </button>
      </li>
      <li className="nav-item">
        <button
          className="nav-link btn btn-link"
          onClick={
            i18n.language !== "de" ? () => changeLanguage("de") : undefined
          }
          style={
            i18n.language !== "de"
              ? { cursor: "pointer", textDecoration: "none" }
              : {
                  cursor: "default",
                  textDecoration: "none",
                  fontWeight: "bold",
                }
          }
        >
          {t("navbar.language.german")}
        </button>
      </li>
      <li className="nav-item">
        <button
          className="nav-link btn btn-link"
          onClick={
            i18n.language !== "en" ? () => changeLanguage("en") : undefined
          }
          style={
            i18n.language !== "en"
              ? { cursor: "pointer", textDecoration: "none" }
              : {
                  cursor: "default",
                  textDecoration: "none",
                  fontWeight: "bold",
                }
          }
        >
          {t("navbar.language.english")}
        </button>
      </li>
    </div>
  );
};

export default LanguageSwitcher;
