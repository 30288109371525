import Select from "react-select";
import { useFuneralHome } from "../../context/FuneralHomeContext";
import { useTranslation } from "react-i18next";

interface SupplierDropdownProps {
  selectedSupplier: string;
  handleChange: (selectedSupplier: string) => void;
}

const SupplierDropdown: React.FC<SupplierDropdownProps> = ({
  selectedSupplier,
  handleChange,
}) => {
  const { t } = useTranslation();
  const { suppliers } = useFuneralHome();

  const supplierOptions = suppliers.map((supplier) => ({
    value: supplier.SUPPLIERID,
    label: supplier.NAME,
  }));

  const handleSelectChange = (selectedOption: any) => {
    handleChange(selectedOption?.value || "");
  };

  supplierOptions.unshift({ value: "", label: t("supplier.select") });

  return (
    <Select
      value={supplierOptions.find(
        (option) => option.value === selectedSupplier
      )}
      onChange={handleSelectChange}
      options={supplierOptions}
      placeholder={t("supplier.select")}
      isClearable
    />
  );
};

export default SupplierDropdown;
